import React from "react"
import { graphql, navigate } from "gatsby"

import Layout from "../components/layout"
import styles from "./handball.module.scss"
import hockeyStyles from "./icehockey.module.scss"
import Header from "../components/header"
import { shuffle } from "../services/shuffle"
import Landing from "../components/index/landing"
import Feature from "../components/iceHockey/feature"
import WeAreWorldwide from "../components/handball/weAreWorldwide"
import Context from "../components/contextProvider"
import Button from "../components/button"
import VideoSection from "../components/iceHockey/videoSection"
import handballVid from "../videos/sports/Handball.mp4"
import videoPoster from "../images/handball/videoPoster.webp"

import { openModal } from "../services/modal"
import CallToActionBlock from "../components/iceHockey/callToActionBlock"
import Footer from "../components/footer"

class HandBallPage extends React.Component {
  static context = Context
  constructor(props) {
    super(props)
    this.state = {
      showSports: false,
      showMessage: false,
      messageTitle: false,
      messageSubtitle: false,
      customerImages: [],
      shuffledLandingImgs: [],
    }
    this.collapseRef = React.createRef()
  }

  showSports(show) {
    this.setState({ showSports: show })
  }
  btnClick(sport) {
    const { languageCode } = this.props
    this.showSports(false)
    this.linkClicked("/sport")
    const link = languageCode
      ? languageCode == "en"
        ? "/sport/?type=" + sport
        : `/${languageCode}/sport/?type=${sport}`
      : "/sport/?type=" + sport
    navigate(link, { state: { sport } })
  }

  linkClicked(urlParam) {
    const location = document.location.href.split("/")
    if (
      this.refs.checkbox &&
      urlParam === "/" + location[location.length - 1]
    ) {
      this.refs.checkbox.checked = false
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  mobileSportsClicked() {
    var content = this.collapseRef.current
    if (content.style.maxHeight) {
      content.style.maxHeight = null
    } else {
      content.style.maxHeight = content.scrollHeight + "px"
    }
  }

  handleSubmit = (e, set) => {
    e.preventDefault()
    const form = e.target
    set({
      signUpOpen: true,
    })
    let email = form.childNodes[1].value
    form.reset()
    setTimeout(() => {
      XPSSignup["_email"](email)
      document.querySelectorAll("input#name")[0].focus()
    })
  }

  componentWillMount() {
    const { landingImgs } = this.props.data

    const shuffledLandingImgs = shuffle(landingImgs.edges)
    this.setState({
      shuffledLandingImgs,
    })
  }

  render() {
    const {
      texts: { _ },
      monitoring,
      collection,
      analysis,
      injury,
      tactic,
      planning,
      communication,
      weAreWorldwideImg,
      flags,
    } = this.props.data

    const sortedFlags =
      flags && flags.edges
        ? flags.edges?.sort((a, b) => {
            return ("" + a.node.name).localeCompare(b.node.name)
          })
        : []

    const languageCode =
      this.props.pageContext && this.props.pageContext.lang
        ? this.props.pageContext.lang
        : "en"

    const { shuffledLandingImgs } = this.state

    return (
      <Layout
        header={
          <Header
            num={1}
            translations={_}
            headerFullYOffset={5000}
            mobileHeaderFullYOffset={40}
            languageCode={languageCode}
            hideNav
            staticHeader
          />
        }
        translations={_}
        languageCode={languageCode}
        gtagIdentificator="G-HVEMV4F6J3"
      >
        <Landing
          imgs={shuffledLandingImgs}
          styles={styles}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          hideOverlay={() => {
            this.setState({ showMessage: false })
          }}
          hideLogo
          heading={
            <>
              Take your handball <br /> to another level
            </>
          }
          subheading={_.index.subheading}
          descriptionItems={[
            "Whole organisation managed in one place",
            "Planning, Monitoring, Analysis, Communication",
            "New XPS Health medical module",
          ]}
          enter_your_email={_.enter_your_email}
          success_title={_.contact_message.success_title}
          success_subtitle={_.contact_message.success_subtitle}
          languageCode={languageCode}
          randomImg={shuffledLandingImgs[0]}
          actionButton={
            <div className={hockeyStyles.landingButtons}>
              <Button onClick={openModal}>BOOK A MEETING</Button>
              <Button onClick={openModal} light>
                START FREE TRIAL
              </Button>
            </div>
          }
        />
        <WeAreWorldwide
          backgroundStyle={styles.featureBackgroundPlanning}
          contentContainerClass={styles.planningContent}
          translations={_}
          rightClip
          order
          header="We are worldwide"
          flags={sortedFlags}
          description="From managing single athletes to national teams, trainers and coaches use XPS Network to improve their performance."
          img={weAreWorldwideImg}
        />
        <VideoSection
          title={_.sports_page.video_title}
          src={handballVid}
          poster={videoPoster}
        />
        <CallToActionBlock title="It's time to get started" />
        <Feature
          name="Monitoring"
          title="Make data-driven coaching decisions"
          description="monitor readiness, wellness and training load"
          image={monitoring?.childImageSharp?.fluid}
        />
        <Feature
          name="Collections"
          title="Create your lifetime of coaching observation"
          description=" build a library of drills, exercises, documents or player information"
          image={collection?.childImageSharp?.fluid}
          leftCut
        />
        <Feature
          name="Analysis"
          title="Analyze your performance"
          description="and generate high levels of accountability and feedback"
          image={analysis?.childImageSharp?.fluid}
          rightCut
        />
        <CallToActionBlock
          title="Handball Hamburg"
          subtitle="XPS Health & XPS Reports in daily use"
          videoId="yqows8d42kc"
        />
        <Feature
          name="Injury Management"
          title="Report injuries and illnesses"
          description="provide seamless communication between your medical team and coaching staff"
          image={injury?.childImageSharp?.fluid}
        />
        <Feature
          name="Tactics"
          title="Recreate training and game situations"
          description="through diagrams and animations in XPS Playbook"
          image={tactic?.childImageSharp?.fluid}
          rightCut
        />
        <Feature
          name="Planning"
          title="Build and plan team and individual sessions"
          description="with Calendar, Session Planner and Collections features"
          image={planning?.childImageSharp?.fluid}
          leftCut
        />
        <Feature
          name="Communication"
          title="Stay in touch with coaches and athletes"
          description="share videos, animations, photos or documents, all in one place"
          image={communication?.childImageSharp?.fluid}
          rightCut
        />
        <CallToActionBlock
          title="XPS in Handball Bundesliga"
          subtitle=" "
          videoId="hmiFx6_Z8V8"
          wrapperStyles={{ marginBottom: 0 }}
        />
        <Footer translations={_} languageCode={languageCode} />
      </Layout>
    )
  }
}

export default HandBallPage
export const query = graphql`
  query Index($langFile: String) {
    site {
      siteMetadata {
        ip_stack_key
      }
    }
    texts: file(relativePath: { eq: $langFile }) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        ...EmailTranslations
        ...ProductPresentationTranslations
        ...TryXpsModalTranslations
        time_to_get_started
        enter_your_email
        start_free_trial
        sports {
          basketball
          field_hockey
          floorball
          handball
          ice_hockey
          other_sports
          performance
          soccer
          tennis
          volleyball
        }
        always_with_you {
          title
          description
        }
        sports_page {
          video_title
        }
        index {
          heading
          subheading
          complete_solution_heading
          see_how_it_works
          planning_and_organization_heading
          planning_and_organization_items
          communication_heading
          communication_items
          analysis_heading
          analysis_items
          tactics_heading
          tactics_items
          trusted_title
          trusted_heading
          trusted_paragraph
          see_what_theyre_saying
          good_company
          thousands_of_coaches
        }
        product_presentation {
          header
        }
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
      }
    }
    sportContent: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/sportsContent/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            icon {
              name
              extension
              relativePath
              publicURL
            }
          }
        }
      }
    }
    products: file(
      relativePath: { eq: "index/productPresentation/products.webp" }
    ) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    customerImgs: allFile(filter: { relativePath: { glob: "tmp/*" } }) {
      edges {
        node {
          name
          extension
          relativePath
          childImageSharp {
            fluid(maxWidth: 893) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    landingImgs: allFile(
      filter: { relativePath: { glob: "index/landingCarousel2/handball.webp" } }
    ) {
      edges {
        node {
          name
          extension
          relativePath
          publicURL
          childImageSharp {
            fluid(maxWidth: 893) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    monitoring: file(relativePath: { eq: "index/monitoring.webp" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    weAreWorldwideImg: file(
      relativePath: { eq: "handball/we_are_worldwide.webp" }
    ) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    collection: file(relativePath: { eq: "handball/collection.webp" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    analysis: file(relativePath: { eq: "handball/analysis.webp" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    injury: file(relativePath: { eq: "index/injury.webp" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tactic: file(relativePath: { eq: "handball/tactic.webp" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    planning: file(relativePath: { eq: "handball/planning_individual.webp" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    communication: file(relativePath: { eq: "index/communication_app.webp" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    flags: allFile(filter: { relativePath: { glob: "flags/*" } }) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            fluid(maxWidth: 55, maxHeight: 55) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
