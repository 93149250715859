import React from "react"
import globalStyles from "../../services/globalStyles.module.scss"
import styles from "./weAreWorldwide.module.scss"
import ClipContainer from "../clipContainer"
import Button from "../button"
import { useInView } from "react-intersection-observer"
import GatsbyImage from "gatsby-image"
import { openModal } from "../../services/modal"

const WeAreWorldwide = ({
  description,
  subHeader,
  img,
  header,
  rightClip,
  backgroundStyle,
  imageContainerStyle,
  noClipping,
  contentContainerClass,
  featureTextContainerClass,
  flags,
  translations,
}) => {
  const { ref, inView } = useInView({
    rootMargin: "200px",
    triggerOnce: true,
  })

  return (
    <div ref={ref}>
      <ClipContainer
        style={{
          position: "relative",
        }}
        none={noClipping}
        bottom
        right={rightClip}
        className={[styles.featureBackground, backgroundStyle].join(" ")}
      >
        <div className={globalStyles.hideOnDesktop}>
          <div className={styles.featureHeader}>{header}</div>
          <div className={styles.featureDescription}>{description}</div>
        </div>
        <div
          className={[
            styles.featureDetailContainer,
            globalStyles.contentPaddingContainer2,
          ].join(" ")}
        >
          <div
            className={[styles.featureDetailWrap, contentContainerClass].join(
              " "
            )}
          >
            <div className={styles.featureDetail}>
              <div
                // style={order && { order: 2 }}
                className={[
                  styles.featureImageContainer,
                  imageContainerStyle,
                ].join(" ")}
              >
                <div className={[styles.flexCenter].join(" ")}>
                  {inView && (
                    <GatsbyImage
                      className={styles.avatar}
                      fluid={img.childImageSharp.fluid}
                    ></GatsbyImage>
                  )}
                </div>

                <div className={styles.flags}>
                  {flags.map((val, i) => {
                    const splitName = val.node.name.split("-")
                    const names = splitName.map((val, index) => {
                      if (val !== "flag" && val.length > 0) {
                        val = val.charAt(0).toUpperCase() + val.slice(1)
                        return val
                      }
                      return null
                    })
                    let name = names.join(" ")
                    if (name.trim() === "Usa") {
                      name = name.toUpperCase()
                    }
                    return (
                      <div key={i} className={styles.flagItem}>
                        <div className={styles.flagImg}>
                          <GatsbyImage
                            fluid={val.node.childImageSharp.fluid}
                            className={styles.flagImg}
                          />
                        </div>
                        <div className={styles.featureDescription}>{name}</div>
                      </div>
                    )
                  })}
                </div>

                <div className={styles.ContainerAction}>
                  <Button
                    classStyle={styles.ContainerButtonActionBookButton}
                    onClick={openModal}
                  >
                    Book a meeting
                  </Button>
                  <span>
                    or
                    <p onClick={openModal}>{translations.start_free_trial}</p>
                  </span>
                </div>
              </div>
              <div
                className={[
                  styles.featureTextContainer,
                  featureTextContainerClass,
                ].join(" ")}
              >
                <div className={styles.featureSubHeader}>{subHeader}</div>
                <div className={styles.featureHeader}>{header}</div>
                <div className={styles.featureDescription}>{description}</div>
              </div>
            </div>
          </div>
        </div>
      </ClipContainer>
    </div>
  )
}

export default WeAreWorldwide
